@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #f8f8f8;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  height: 100vh;
}

button, input, select, option {
  font-family: 'Noto Sans', sans-serif;
}

a {
  text-decoration: none;
}

ul, li {
  list-style-type: none;
}

img {
  max-width: 100%;
}

header {
  position: fixed;
  width: 100%;
  padding: 15px 0;
  z-index: 99999;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

header.sticky-header {
  background: #fff;
  padding: 10px 0;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

footer {
  background: #262626;
}

.background--ultra-light-gray {
  background: #f8f8f8;
}

.background--light-gray {
  background: #eee;
}

.background--dark-green {
  background: #67ac36;
}

.cursor-field {
  cursor: pointer;
}

.section-size {
  padding: 60px 0;
}

.custom-link {
  color: #67ac36;
  font-weight: 500;
}

.custom-link:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.page-size {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  position: relative;
  font-family: 'Fira Sans', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.section-title::after {
  position: absolute;
  content: '';
  bottom: -3px;
  left: 0;
  width: 75px;
  height: 4px;
  background: #81d742;
}

.section-subtitle {
  margin-bottom: 50px;
}

.top-margin {
  margin-bottom: 20px;
}

.bottom-margin {
  margin-bottom: 20px;
}

.big-bottom-margin {
  margin-bottom: 50px;
}

.page-not-found-wrapper {
  padding: 100px 0;
}

.page-not-found-wrapper .page-not-found {
  color: #67ac36;
  font-size: 30px;
  text-align: center;
  marign-bottom: 20px;
}

.page-not-found-wrapper .page-not-found.error {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 700;
}

.page-not-found-wrapper .page-not-found.btn {
  display: inline-block;
}

.custom-btn {
  display: inline-block;
  color: #fff;
  background: linear-gradient(167deg, #81d742 0%, #67ac36 100%);
  padding: 12px 20px;
  border-radius: 3px;
}

.page--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
}

.page--flex .page-content {
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
}

.page--flex .page-content p {
  margin-bottom: 10px;
}

.page--flex .page-form {
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
}

@media all and (min-width: 768px) {
  .page--flex {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.list--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
  margin: 25px 0 35px 0;
}

.list--flex .list-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.list--flex .list {
  margin: 15px 0 0 30px;
}

.list--flex .list li {
  font-size: 14px;
  list-style-type: initial;
}

@media all and (min-width: 1024px) {
  .list--flex {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.msg-background {
  border-radius: 10px;
  padding: 15px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.msg-background.msg-red {
  background-color: #a80b00;
}

.msg-background.msg-green {
  background: linear-gradient(167deg, #81d742 0%, #67ac36 100%);
}

.msg-background .msg-text {
  color: #fff;
  font-size: 12px;
}

::-moz-selection {
  color: #81d742;
}

::selection {
  color: #81d742;
}

::-webkit-input-placeholder {
  color: #262626;
}

:-ms-input-placeholder {
  color: #262626;
}

::-ms-input-placeholder {
  color: #262626;
}

::placeholder {
  color: #262626;
}

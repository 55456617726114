.heading-section {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.heading-section::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 172, 54, 0.85);
}

.heading-section img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.heading-section .heading-content {
  position: absolute;
  left: 50%;
  bottom: 50px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.heading-section .heading-content .heading-title, .heading-section .heading-content .heading-description {
  color: #fff;
  text-align: center;
}

.heading-section .heading-content .heading-title {
  font-size: 36px;
}

.heading-section .heading-content .heading-description {
  font-size: 20px;
}

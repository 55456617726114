.grid-cards {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
}

.grid-cards.grid-two-cols {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
}

.grid-cards.grid-three-cols {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.small-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 22px;
  border: 0;
  border-bottom: 6px solid #81d742;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.small-card .small-card--title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  font-family: 'Fira Sans', sans-serif;
}

.small-card .small-card--title .icon {
  font-size: 22px;
  color: #81d742;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: rgba(129, 215, 66, 0.3);
  text-align: center;
  padding: 14px;
  border-radius: 100%;
}

.small-card .small-card--title .content--title {
  font-size: 20px;
  line-height: 24px;
}

.small-card .small-card--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.small-card .small-card--content .content--description {
  font-size: 14px;
}

.small-card .small-card--link {
  margin-top: 10px;
}

.small-card .small-card--link .link {
  position: relative;
  color: #fff;
  background: #67ac36;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 16px;
  border-radius: 3px;
  opacity: .8;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.small-card:hover {
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

.small-card:hover .link {
  opacity: 1;
}

.box-background {
  background: #f7f7f7;
}

.box-cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 60px;
          column-gap: 60px;
  row-gap: 30px;
  margin-left: 30px;
}

.box-cards .box-card {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: 30% 70%;
      grid-template-columns: 30% 70%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.box-cards .box-card .box-image {
  position: relative;
  max-width: 200px;
  height: 175px;
  margin-left: -50px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 10px rgba(103, 172, 54, 0.5);
          box-shadow: 0 5px 10px rgba(103, 172, 54, 0.5);
  overflow: hidden;
}

.box-cards .box-card .box-image::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(167deg, #81d742 0%, #67ac36 100%);
  opacity: .25;
}

.box-cards .box-card .box-image .image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.box-cards .box-card .box-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px 0;
  margin-right: 20px;
}

.box-cards .box-card .box-content .card-title {
  font-size: 20px;
}

.box-cards .box-card .box-content .card-subtitle {
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 500;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.box-cards .box-card .box-content .card-link {
  display: inline-block;
  color: #fff;
  background: linear-gradient(167deg, #81d742 0%, #67ac36 100%);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.timeline-section {
  position: relative;
  margin-bottom: 60px;
}

.timeline-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 172, 54, 0.85);
}

.timeline-section .timeline-section--inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  z-index: 1;
}

.timeline-section .timeline-section--inner .timeline-image--container {
  position: relative;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}

.timeline-section .timeline-section--inner .timeline-image--container .timeline-image {
  position: absolute;
  top: 25px;
  left: -50px;
  width: 100%;
  max-width: 575px;
  max-height: 575px;
  border-radius: 100%;
  -webkit-filter: drop-shadow(0 20px 35px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 20px 35px rgba(0, 0, 0, 0.2));
}

.timeline-section .timeline-section--inner .timeline-image--container .timeline-image::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -25px;
  width: 100%;
  height: 100%;
  background: #a7da49;
  border-radius: 100%;
  opacity: .6;
  z-index: -1;
}

.timeline-section .timeline-section--inner .timeline-image--container .timeline-image::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: -25px;
  width: 100%;
  height: 100%;
  background: #a7da49;
  border-radius: 100%;
  opacity: .4;
  z-index: -1;
}

.timeline-section .timeline-section--inner .timeline-image--container .timeline-image .image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.timeline-section .timeline-section--inner .timeline-content {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}

.timeline-section .timeline-section--inner .timeline-content .section-title {
  color: #fff;
  margin-bottom: 20px;
}

.timeline-section .timeline-section--inner .timeline-content .section-title::after {
  background: #fff;
}

.timeline-section .timeline-section--inner .timeline-content .description {
  color: #fff;
  font-size: 14px;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container {
  margin-top: 20px;
  margin-bottom: 40px;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 35px;
  padding: 10px 0;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row::before, .timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row::after {
  position: absolute;
  content: '';
  width: 3px;
  height: 50%;
  left: 19px;
  background: #fff;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row::before {
  top: 0;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row::after {
  bottom: 0;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row:last-child::after {
  height: 0;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row:first-child::before {
  height: 0;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row .step {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #262626;
  background: #fff;
  text-align: center;
  font-weight: 700;
  border-radius: 100%;
  z-index: 1;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row .body .title {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-container .timeline-row .body .description {
  margin: 0;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-btn .button {
  color: #262626;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  border: 2px solid #fff;
  border-radius: 3px;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-btn .button.btn-initial {
  color: #fff;
  background: transparent;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-btn .button.btn-initial:hover {
  color: #262626;
  background: #fff;
  border-color: #fff;
}

.timeline-section .timeline-section--inner .timeline-content .timeline-btn .button:hover {
  color: #fff;
  background: #2a4f0f;
  border-color: #2a4f0f;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  height: 700px;
}

.slider-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #f8f8f8;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.slider-wrapper .slider-wrapper--inner {
  position: relative;
  top: 50%;
  width: 100%;
  height: 100%;
  max-height: 475px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.slider-wrapper .slider-wrapper--inner .slider-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-top: 75px;
}

.slider-wrapper .slider-wrapper--inner .slider-item.active {
  opacity: 1;
  visibility: visible;
}

.slider-wrapper .slider-wrapper--inner .slider-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 40px;
  height: 100%;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-image {
  position: relative;
  width: 450px;
  min-width: 450px;
  height: 450px;
  border-radius: 100%;
  z-index: 10;
  -webkit-filter: drop-shadow(0 20px 35px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 20px 35px rgba(0, 0, 0, 0.2));
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-image::before {
  content: '';
  position: absolute;
  top: -10px;
  right: -25px;
  width: 100%;
  height: 100%;
  background: #a7da49;
  border-radius: 100%;
  opacity: .6;
  z-index: -1;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-image::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: -25px;
  width: 100%;
  height: 100%;
  background: #a7da49;
  border-radius: 100%;
  opacity: .4;
  z-index: -1;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-image .image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: calc(1200px / 2);
  height: 100%;
  max-height: 325px;
  margin-top: 25px;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-container .slider-title {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  font-size: 38px;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-container .slider-subtitle {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  font-size: 30px;
  margin-bottom: 20px;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-container .slider-text {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-container .slider-btn .btn {
  position: relative;
  display: inline-block;
  color: #fff;
  background: #4c8821;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  border: 2px solid #4c8821;
  border-radius: 3px;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  z-index: 2;
}

.slider-wrapper .slider-wrapper--inner .slider-flex .slider-container .slider-btn .btn:hover {
  color: #262626;
  background: #fff;
  border: 2px solid #fff;
}

.slider-wrapper .slider-wrapper--inner .slider-controls {
  position: absolute;
  bottom: -50px;
  left: calc((100% - 1240px) / 2);
}

.slider-wrapper .slider-wrapper--inner .slider-controls .slider-arrow {
  position: absolute;
  bottom: 25px;
  width: 40px;
  height: 40px;
  color: #262626;
  background: #fff;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 100%;
  outline: 0;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
}

.slider-wrapper .slider-wrapper--inner .slider-controls .slider-arrow.arrow-next {
  left: 75px;
}

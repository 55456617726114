.header-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 50px;
  padding-bottom: 8px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.header-section .nav-logo {
  max-width: 175px;
  min-width: 175px;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.header-section .nav-logo .logo-color {
  display: none;
}

.header-section .nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 25px;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.sticky-header .header-section {
  padding-bottom: 0;
}

.sticky-header .header-section .nav-logo {
  max-width: 145px;
  min-width: 145px;
}

.sticky-header .header-section .nav-logo .logo-white {
  display: none;
}

.sticky-header .header-section .nav-logo .logo-color {
  display: block;
}

.sticky-header .header-section .nav-menu {
  gap: 15px;
}

.page-credits {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.page-credits .page-title {
  display: inline-block;
  position: relative;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.page-credits .page-title::before, .page-credits .page-title::after {
  position: absolute;
  content: '';
  top: 50%;
  width: 75px;
  height: 2px;
  background: #262626;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.page-credits .page-title::before {
  left: -100px;
}

.page-credits .page-title::after {
  right: -100px;
}

.page-credits .page-logo {
  max-width: 225px;
}

.footer-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
  margin-top: 50px;
}

.footer-grid .footer-title {
  position: relative;
  color: #eee;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footer-grid .footer-title::after {
  position: absolute;
  content: '';
  bottom: -5px;
  left: 0;
  width: 40px;
  height: 3px;
  background: #81d742;
}

.footer-grid .contact-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
}

.footer-grid .contact-group .contact-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
}

.footer-grid .contact-group .contact-item i {
  color: #81d742;
}

.footer-grid .footer-text {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}

.footer-grid .contact-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
}

.footer-grid .contact-links .contact-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
}

.footer-grid .contact-links .contact-link i {
  color: #81d742;
  font-size: 12px;
}

.footer-grid .contact-links .contact-link:hover {
  color: #81d742;
  margin-left: 5px;
}

.footer-grid .iframe-footer {
  width: 300px;
  height: 130px;
}

.footer-grid .footer-promo-img {
  display: block;
  max-width: 150px;
  margin-top: 30px;
}

@media all and (min-width: 625px) {
  .footer-grid {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (min-width: 1200px) {
  .footer-grid {
    -ms-grid-columns: (1fr)[4];
        grid-template-columns: repeat(4, 1fr);
  }
}

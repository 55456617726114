.accordion-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.accordion-item {
  border: 1px solid #f8f8f8;
  border-bottom: 1px solid #eee;
}

.accordion-item .accordion-head {
  background: #f8f8f8;
  padding: 14px 20px;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.accordion-item .accordion-head:hover, .accordion-item .accordion-head.active {
  color: #fff;
  background: #67ac36;
}

.accordion-item .accordion-head .accordion-icon {
  margin-right: 20px;
}

.accordion-item .accordion-body {
  background: #fff;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion-item .accordion-body.active {
  height: auto;
  max-height: 9999px;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.accordion-item .accordion-body .accordion-inner-body {
  padding: 10px 20px 20px;
}

.accordion-item .accordion-body .accordion-paragraph {
  font-size: 14px;
}

.accordion-item .accordion-body .accordion-paragraph:not(:last-child) {
  margin-bottom: 10px;
}

.page-form form {
  position: relative;
  max-width: 325px;
  min-width: 250px;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.page-form form .line-set {
  position: absolute;
  width: 100%;
  height: 100%;
}

.page-form form .line-set::before, .page-form form .line-set::after {
  position: absolute;
  content: '';
  background: linear-gradient(167deg, #81d742 0%, #67ac36 100%);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.page-form form .first-line-set {
  top: 0;
  left: 0;
}

.page-form form .first-line-set::before {
  top: 0;
  left: 0;
  width: 25%;
  height: 3px;
}

.page-form form .first-line-set::after {
  top: 0;
  left: 0;
  width: 3px;
  height: 25%;
}

.page-form form .second-line-set {
  bottom: 0;
  right: 0;
}

.page-form form .second-line-set::before {
  bottom: 0;
  right: 0;
  width: 25%;
  height: 3px;
}

.page-form form .second-line-set::after {
  bottom: 0;
  right: 0;
  width: 3px;
  height: 25%;
}

.page-form form:hover .first-line-set::before {
  width: 50%;
}

.page-form form:hover .first-line-set::after {
  height: 50%;
}

.page-form form:hover .second-line-set::before {
  width: 50%;
}

.page-form form:hover .second-line-set::after {
  height: 50%;
}

.page-form form .form-title {
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.page-form form .form-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.page-form form .form-row:not(:last-child) {
  margin-bottom: 12px;
}

.page-form form .form-row .form-label {
  font-size: 13px;
  margin-bottom: 5px;
}

.page-form form .form-row .label-star {
  color: #f01202;
}

.page-form form .form-row .form-field {
  width: 100%;
  background: rgba(0, 0, 0, 0.025);
  padding: 10px 14px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.page-form form .form-row .form-field:focus {
  border: 0;
  border-bottom: 1px solid #81d742;
}

.page-form form .form-row .field-error {
  color: #f01202;
  font-size: 12px;
  margin-top: 3px;
}

.page-form form .form-row .react-datepicker__close-icon {
  padding-right: 10px;
}

.page-form form .form-row .react-datepicker__close-icon::after {
  background: #67ac36;
  border-radius: 6px;
}

.page-form form .form-row .react-datepicker {
  border-color: #81d742;
}

.page-form form .form-row .react-datepicker .react-datepicker__header {
  background: #67ac36;
  text-transform: capitalize;
}

.page-form form .form-row .react-datepicker .react-datepicker__header .react-datepicker__current-month,
.page-form form .form-row .react-datepicker .react-datepicker__header .react-datepicker-time__header,
.page-form form .form-row .react-datepicker .react-datepicker__header .react-datepicker-year-header,
.page-form form .form-row .react-datepicker .react-datepicker__header .react-datepicker__day-name,
.page-form form .form-row .react-datepicker .react-datepicker__header .react-datepicker__day,
.page-form form .form-row .react-datepicker .react-datepicker__header .react-datepicker__time-name {
  color: #fff;
}

.page-form form .form-row .react-datepicker .react-datepicker__navigation-icon::before {
  border-color: #fff;
}

.page-form form .form-row .react-datepicker .react-datepicker__day--keyboard-selected,
.page-form form .form-row .react-datepicker .react-datepicker__month-text--keyboard-selected,
.page-form form .form-row .react-datepicker .react-datepicker__quarter-text--keyboard-selected,
.page-form form .form-row .react-datepicker .react-datepicker__year-text--keyboard-selected,
.page-form form .form-row .react-datepicker .react-datepicker__day--selected,
.page-form form .form-row .react-datepicker .react-datepicker__day--in-selecting-range,
.page-form form .form-row .react-datepicker .react-datepicker__day--in-range,
.page-form form .form-row .react-datepicker .react-datepicker__month-text--selected,
.page-form form .form-row .react-datepicker .react-datepicker__month-text--in-selecting-range,
.page-form form .form-row .react-datepicker .react-datepicker__month-text--in-range,
.page-form form .form-row .react-datepicker .react-datepicker__quarter-text--selected,
.page-form form .form-row .react-datepicker .react-datepicker__quarter-text--in-selecting-range,
.page-form form .form-row .react-datepicker .react-datepicker__quarter-text--in-range,
.page-form form .form-row .react-datepicker .react-datepicker__year-text--selected,
.page-form form .form-row .react-datepicker .react-datepicker__year-text--in-selecting-range,
.page-form form .form-row .react-datepicker .react-datepicker__year-text--in-range {
  background: #67ac36;
}

.page-form form .form-row .react-datepicker .react-datepicker__day--in-selecting-range {
  opacity: .8;
}

.page-form form .form-row .text-field {
  resize: none;
  min-height: 125px;
}

.page-form form .form-row .custom-check {
  display: block;
  position: relative;
  font-size: 12px;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.page-form form .form-row .custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.page-form form .form-row .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  border: 2px solid #81d742;
  border-radius: 4px;
}

.page-form form .form-row .custom-check input:checked ~ .checkmark {
  background-color: #81d742;
}

.page-form form .form-row .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.page-form form .form-row .custom-check input:checked ~ .checkmark:after {
  display: block;
}

.page-form form .form-row .custom-check .checkmark:after {
  top: 1px;
  left: 4px;
  width: 2px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.page-form form .form-row .form-submit {
  display: inline-block;
  max-width: 125px;
  color: #fff;
  background: linear-gradient(167deg, #81d742 0%, #67ac36 100%);
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  border-radius: 5px;
  margin-top: 30px;
  padding: 10px 0;
}

@media all and (max-width: 768px) {
  .page-form form {
    margin: 0 auto;
  }
}

.page-gallery {
  margin-bottom: 30px;
}

.page-gallery .image-url {
  position: relative;
  max-height: 400px;
  margin-bottom: 7px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.page-gallery .image-url::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.2)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.page-gallery .image-url .gallery-first-image {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}

.page-gallery .gallery-thumbs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  grid-gap: 7px;
}

.page-gallery .gallery-thumbs .thumb {
  max-height: 100px;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.page-gallery .gallery-thumbs .thumb.thumb-active {
  position: relative;
}

.page-gallery .gallery-thumbs .thumb.thumb-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #67ac36;
  opacity: .2;
}

.page-gallery .gallery-thumbs .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.lg-container {
  position: relative;
  z-index: 999999;
}

.gallery-big-image {
  position: relative;
}

.gallery-big-image .gallery-controlls {
  position: absolute;
  top: calc(100% - 60px);
  right: 25px;
}

.gallery-big-image .gallery-controlls .gallery-arrow {
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid #fff;
  margin: 0 5px;
  border-radius: 100%;
  cursor: pointer;
}

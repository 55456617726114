.contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.contact-info .contact-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
}

.contact-info .contact-icon {
  color: #81d742;
  font-size: 18px;
}

.sticky-header .contact-info {
  color: #262626;
}

.sticky-header .contact-info .contact-icon {
  color: #67ac36;
}

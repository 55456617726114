.section-contactus {
  position: relative;
  margin-bottom: -70px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px rgba(103, 172, 54, 0.6);
          box-shadow: 0 0 20px rgba(103, 172, 54, 0.6);
}

.section-contactus .contactus--inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 30px;
  padding: 25px 40px;
}

.section-contactus .contactus--inner .contactus--content {
  max-width: 850px;
}

.section-contactus .contactus--inner .contactus--content .title {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
}

.section-contactus .contactus--inner .contactus--content .description {
  color: #fff;
  font-size: 14px;
}

.section-contactus .contactus--inner .contactus--btn {
  min-width: 200px;
  text-align: right;
}

.section-contactus .contactus--inner .contactus--btn .custom-btn {
  color: #262626;
  background: #fff;
  font-size: 14px;
  font-weight: 600;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
